import { QuestionAndAnswer } from '../../components/common/q-and-a'

const questionsAndAnswers: QuestionAndAnswer[] =  [
  {
    question: "Can I change a Privacy Policy that I have published?", 
    answer: "No. All published Policies are immutable."
  }, 
  {
    question: "Can I copy a published Policy, change the copy and publish it?", 
    answer: "Yes. "
  },
 {
    question: "Can I prove which information I provided in a published Privacy Policy at a given time?", 
    answer: "Yes. All published Policy versions are immutable, recorded and stored with a time stamp of the date of publication."
  },
 {
    question: "Should I ask end users to consent to my Privacy Policy?", 
    answer: "No."
  },
 {
    question: "How can I document that end users have been provided with my Privacy Policy?", 
    answer: "You can use Signatu’s Consent technology to document and prove that end users confirm you have provided your Policy to them."
  },
 {
    question: "Can I prove that my Privacy Policy was provided at a URL at a given time?", 
    answer: "Yes. Use Trackerdetect to scan and make a screenshot of the URL daily. Screenshots are recorded and stored."
  },
 {
    question: "How do I inform end users that I use other companies on my site?", 
    answer: "Use Trackerdetect to discover other companies on sites automatically and use that information in your Privacy Policy to inform end users that you use other companies on your site."
  },
 {
    question: "Can I style my Privacy Policy to make it fit my site?", 
    answer: "Yes. You can change the colors and typography of the policy."
  },
 {
    question: "How can I be sure that the Privacy Policy I have embedded on my site will be available?", 
    answer: "Your Policy is delivered via a content delivery network (CDN). The CDN is globally distributed and delivers your Policy with low latency and high transfer speeds."
  },
 {
    question: "Must my consent requests be present in my Privacy Policy?", 
    answer: "Yes. In the Policy generator, you can integrate your consent requests that you have specified in the tool for Data Processing Specification."
  }
]

export default questionsAndAnswers
