import React from 'react'
import Policy1 from './policy-cartoon1.png'
import Policy2 from './policy-cartoon2.png'
import Policy3 from './policy-cartoon3.png'
import { Carousel } from '@signatu/common-react'

export default () => (
    <Carousel>
        <img src={Policy1} />
        <img src={Policy2} />
        <img src={Policy3} />
    </Carousel>
)
